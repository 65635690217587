<template>
    <div class="p-grid" v-if="mostrarListagem">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-users"></i> Usuários</h5>
                    <btn-refresh @click="atualizar()"></btn-refresh>
                </div>
                <tabela :data="usuarios" dataKey="usuarioId" :globalFilterFields="['nome', 'login', 'email']">
                    <template #botoes>
                        <btn-inserir></btn-inserir>
                    </template>
                    <template #conteudo>
                        <Column headerStyle="width: 3em">
                            <template #body="slotProps">
                                <btn-detalhar :usuario="slotProps.data"></btn-detalhar>
                            </template>
                        </Column>
                        <Column field="nome" header="Nome" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.nome }}
                            </template>
                        </Column>
                        <!-- <Column field="cpf" header="CPF" :sortable="true">
                            <template #body="slotProps">
                                {{ $toCpf(slotProps.data.cpf) }}
                            </template>
                        </Column> -->
                        <Column field="login" header="Login" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.login }}
                            </template>
                        </Column>
                        <Column field="email" header="Email" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.email }}
                            </template>
                        </Column>
                        <Column field="statusDescr" header="Status" :sortable="true">
                            <template #body="slotProps">
                                <status :status="slotProps.data.statusDescr"></status>
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
    <router-view></router-view>
</template>

<script>
import UsuariosServices from './services';
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnDetalhar,
        BtnInserir,
    },

    data() {
        return {
            usuarios: null,
        };
    },

    methods: {
        obterUsuarios() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterTodos().then(response => {
                if (response && response.success) {
                    this.usuarios = response.data;
                } else {
                    this.usuarios = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterUsuarios();
        },
    },

    mounted() {
        this.obterUsuarios();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Usuarios';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Usuarios') {
                if (this.$store.getters.atualizar) {
                    this.obterUsuarios();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
